/* eslint-disable no-unused-vars */
import { getCurrentInstance } from '@vue/composition-api'

import store from '@/store'

import { canAccess } from '@core/libs/acl/utils'

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        canAccess,
        // canAccess(functionName) {
        //   const funcScope = store.getters['userStore/getFunctionScopesbyFunctionName'](functionName)
        //   if (funcScope) {
        //     const scope = funcScope.requiredScopes
        //     const vm = getCurrentInstance().proxy
        //     return vm.$can ? vm.$can(scope) : true
        //   }
        //   return false
        // },
      },
    })
  },
}
