var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-w-100",
    style: {
      width: _vm.size,
      height: _vm.size
    },
    domProps: {
      "innerHTML": _vm._s(_vm.svgContent)
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }