export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        appBreakPoint() {
          return this.$store.getters['app/currentBreakPoint']
        },
        isMobileView() {
          const breakPoint = this.appBreakPoint
          return breakPoint === 'xs' || breakPoint === 'sm'
        },
      },
    })
  },
}
