import { setFavicon, stringOrArrayToArray } from '@/@core/utils/utils'
import { apiPublic } from '@/api/'
import { env } from '@/libs/env'

import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    // typically used when calling api, set loading state to true then the page will show spinner icon (loading animation)
    loading: false,
    textLoading: null,
    windowWidth: 0,
    shallShowOverlay: false,
    toastOption: {}, // @see @params of showToast() in src/App.vue
    toastTrigger: 0,
    env: process.env.VUE_APP_ENV,
    agencyConfig: [],
    titleSite: undefined,
  },
  getters: {
    getEnv: state => state.env,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getLoading: state => state.loading,
    getTextLoading: state => state.textLoading,
    getBanner: state => state.agencyConfig?.find(e => e.key === 'banner')?.value,
    getBannerSearch: state => stringOrArrayToArray(state.agencyConfig?.find(e => e.key === 'bannerSearch')?.value),
    getLogo: state => state.agencyConfig?.find(e => e.key === 'logo')?.value,
    getFavicon: state => state.agencyConfig?.find(e => e.key === 'favicon')?.value,
    getEmail: state => state.agencyConfig?.find(e => e.key === 'email')?.value,
    getLogoNoti: state => state.agencyConfig?.find(e => e.key === 'logoNoti')?.value,
    getTitleSite: state => state.titleSite,
  },
  mutations: {
    UPDATE_LOADING(state, val) {
      state.loading = val
    },
    UPDATE_TEXT_LOADING(state, val) {
      state.textLoading = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_TOAST_OPTION(state, toastOption) {
      state.toastOption = toastOption
    },
    TRIGGER_SHOW_TOAST(state) {
      state.toastTrigger += 1
    },
    UPDATE_AGENCY_CONFIG(state, val) {
      state.agencyConfig = val
    },
    UPDATE_TITLE_SITE(state, val) {
      state.titleSite = val
    },
  },
  actions: {
    setLoading({ commit }, val) {
      commit('UPDATE_LOADING', val)
      if (val === false) {
        commit('UPDATE_TEXT_LOADING', null)
      }
    },
    setTextLoading({ commit }, val) {
      commit('UPDATE_TEXT_LOADING', val)
    },
    showToast({ commit }, toastOption) {
      commit('SET_TOAST_OPTION', toastOption)
      commit('TRIGGER_SHOW_TOAST')
    },
    showToastError({ dispatch }, toastOption) {
      dispatch('showToast', {
        ...toastOption,
        success: false,
      })
    },
    showToastSuccess({ dispatch }, toastOption) {
      dispatch('showToast', {
        ...toastOption,
        success: true,
      })
    },
    loadConfig({ commit }, { hostname, clearCache = false }) {
      return new Promise((resolve, reject) => {
        if (hostname.includes('localhost') && env.isDevelopment) {
          hostname = 'dev.airdata.site'
        }
        const cacheKey = `agencyConfig_${hostname}`
        const cacheKeyTitle = `agencyConfig_${hostname}_title`
        const cacheExpiryKey = `${cacheKey}_expiry`

        const cachedConfigs = localStorage.getItem(cacheKey)
        const cachedTitleSite = localStorage.getItem(cacheKeyTitle)
        const cacheExpiry = localStorage.getItem(cacheExpiryKey)

        const now = Date.now()

        if (
          cachedConfigs
          && cachedTitleSite
          && cacheExpiry
          && now < cacheExpiry
          && !clearCache
        ) {
          // Dữ liệu trong cache vẫn hợp lệ
          commit('UPDATE_AGENCY_CONFIG', JSON.parse(cachedConfigs))
          commit('UPDATE_TITLE_SITE', cachedTitleSite)
          document.title = cachedTitleSite
          const logoUrl = JSON.parse(cachedConfigs).find(e => e.key === 'favicon')?.value || env.logoFavicon
          setFavicon(logoUrl)
          resolve(JSON.parse(cachedConfigs))
        } else {
          // Dữ liệu không có trong cache hoặc đã hết hạn
          apiPublic
            .getAgencyConfig(hostname)
            .then(res => {
              const { configs, title } = res.data
              if (configs) {
                commit('UPDATE_AGENCY_CONFIG', configs)
                // Lưu vào localStorage với thời gian hết hạn là 5 phút
                localStorage.setItem(cacheKey, JSON.stringify(configs))
                localStorage.setItem(cacheExpiryKey, now + 5 * 60 * 1000) // 5 phút
                const logoUrl = configs.find(e => e.key === 'favicon')?.value || env.logoFavicon
                setFavicon(logoUrl)
              }
              if (title) {
                commit('UPDATE_TITLE_SITE', title)
                localStorage.setItem(cacheKeyTitle, title)
                document.title = title
              }
              resolve(res.data)
            })
            .catch(error => {
              // localStorage.clear()
              reject(error)
            })
        }
      })
    },
  },
}
