import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import VueCompositionAPI from '@vue/composition-api'
import {
  ModalPlugin, ToastPlugin, VBTogglePlugin,
} from 'bootstrap-vue'
import Vue from 'vue'
import infiniteScroll from 'vue-infinite-scroll'

import i18n from '@/libs/i18n'

import App from './App.vue'
import router from './router'
import store from './store'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
// import '@/libs/clipboard'
// import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification/index'
// import '@/libs/tour'
import '@/libs/vue-select'
import '@axios'

// Axios Mock Adapter
// import '@/@fake-db/db'

Vue.prototype.$bus = new Vue()

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VBTogglePlugin)
Vue.use(infiniteScroll)

// Composition API
Vue.use(VueCompositionAPI)

if (process.env.VUE_APP_APM_SERVICE_URL) {
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: process.env.VUE_APP_APM_SERVICE_NAME,
      serverUrl: process.env.VUE_APP_APM_SERVICE_URL,
    },
  })
}

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
