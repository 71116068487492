/* eslint-disable no-unused-vars */
// import { defineAbility } from '@casl/ability'

function defineRuleFor(grantedScopes) {
  return grantedScopes.map(i => ({ action: i.scope }))
  // return defineAbility(can => { grantedScopes.forEach(item => can(item.scope)) })
}

export default store => {
  const ability = store.getters['userStore/ability']
  ability.update(defineRuleFor(store.getters['userStore/getGrantedScopes']))
  store.dispatch('userStore/setAbility', ability)

  return store.subscribe(
    mutation => {
      switch (mutation.type) {
        case 'userStore/SET_GRANTED_SCOPES':
          ability.update(defineRuleFor(mutation.payload))
          store.dispatch('userStore/setAbility', ability)
          break
        case 'userStore/CLEAR_ABILITY':
          ability.update([])
          break
        default:
      }
    },
    { prepend: true },
  )
}
