import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import globalConfig from './global-config'
import userStore from './user-store/userStore'
import abilityPlugin from './user-store/ability'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [abilityPlugin],
  modules: {
    app,
    appConfig,
    verticalMenu,
    globalConfig,
    userStore,
    // 'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
