<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :style="{ width: size, height: size }"
    class="svg-w-100"
    v-html="svgContent"
  />

  <!-- SECTION Example usage -->
  <!-- ANCHOR default -->
  <!--  <IconSvg
          :src="require('@icons/closeX.svg')"
        /> -->
  <!-- ANCHOR use props: color = red, ##EF5350 -->
  <!--  <IconSvg
          :src="require('@icons/closeX.svg')"
          size="48px"
          color="#EF5350"
        /> -->
  <!-- ANCHOR use class-->
  <!--  <IconSvg
          :src="require('@icons/closeX.svg')"
          size="48px"
          class="text-primary"
        /> -->

  <!-- ANCHOR use class with hover -->
  <!--  <IconSvg
          :src="require('@icons/closeX.svg')"
          size="48px"
          class="text-with-hover"
        /> -->

  <!-- ANCHOR use style -->
  <!--  <IconSvg
          :src="require('@icons/closeX.svg')"
          size="48px"
          style="color: green;"
        /> -->
  <!-- !SECTION -->
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '24px',
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
  computed: {
    svgContent() {
      // Load the SVG file using an XMLHttpRequest
      const xhr = new XMLHttpRequest()
      xhr.open('GET', this.src, false)
      xhr.send()
      let svgText = xhr.responseText

      // Replace all <text> elements with <foreignObject> elements
      svgText = svgText.replace(/<text([^>]*)>(.*?)<\/text>/g, '<foreignObject$1><span xmlns="http://www.w3.org/1999/xhtml">$2</span></foreignObject>')

      // Insert the color style attribute on the <svg> element
      svgText = svgText.replace('<svg', `<svg style="color: ${this.color}"`)

      return svgText
    },
  },
}
</script>

<style>
.svg-w-100>svg {
  width: 100%;
  height: 100%;
}
</style>
